import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import NunitoText from "./NunitoText";
import {BiCheckCircle} from 'react-icons/bi'
import {IoMdPerson } from 'react-icons/io'
import { IoCloseCircleOutline} from 'react-icons/io5'
import {FaGraduationCap} from 'react-icons/fa'
import html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import {AiFillFlag} from 'react-icons/ai'
import detectZoom from 'detect-zoom';

import './App.css';
import './datatable.css'
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import Download from "./download";
function StudentAnswers({questions}){
  const makeSureIsArray = arr => Array.isArray(arr) ? arr : Object.values(arr)
  return <Grid container marginTop={'10px'} alignItems={'center'} display={'grid'} width={'100%'} gridTemplateColumns={'auto auto auto auto auto'} columnGap={'35px'} rowGap={'15px'}>
      {makeSureIsArray(questions).map((el , index)=>{
          return <Grid display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}  padding={'20px'} backgroundColor={'#dddddd'} borderRadius={'35px'}>
          <NunitoText value={`Q${index+1}`} fontSize={20} fontWeight={700}  color='#555' align={'left'}></NunitoText>
          <NunitoText value={el?'Correct':'Wrong'} fontSize={20} fontWeight={700}  color='#000' align={'center'}></NunitoText>
         {el?<BiCheckCircle style={{color:'#144A94' , fontSize:'20px'}}/>: <IoCloseCircleOutline style={{color:'red' , fontSize:'20px'}}/>}  
          </Grid>
      })}
  </Grid>
}
function StudentAnswersTopics({topics}){
  const makeSureIsArray = arr => Array.isArray(arr) ? arr : Object.values(arr)
  return <Grid container marginTop={'15px'} display={'grid'} width={'100%'} gridTemplateColumns={'auto auto auto auto auto'} columnGap={'35px'} rowGap={'15px'}>
      {topics.map((el)=>{
          return <Grid display={'flex'} flexDirection={'column'} justifyContent={'space-between'}  width={'100%'} padding={'20px'} backgroundColor={'#144A94'} borderRadius={'25px'}>
          <NunitoText value={el.topic} fontSize={30} fontWeight={700}  color='#fff' align={'left'} marginBottom={'10px'}></NunitoText>
          <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <NunitoText value={`${el.participant}%`} fontSize={30} fontWeight={700}  color='#fff' align={'left'}></NunitoText>
             <IoMdPerson style={{fontSize:'30px', color:'#fff'}}/>
          </Grid>
          <hr width={'100%'}></hr>
          <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <NunitoText value={`${el.school}%`} fontSize={20} fontWeight={700}  color='#fff' align={'left'}></NunitoText>
              <FaGraduationCap style={{fontSize:'30px', color:'#fff'}}/>
          </Grid>
          <hr width={'100%'}></hr>
          <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <NunitoText value={`${el.country}%`} fontSize={20} fontWeight={700}  color='#fff' align={'left'}></NunitoText>
              <AiFillFlag style={{fontSize:'30px', color:'#fff'}}/>
          </Grid>
          
          </Grid>
      })}
     
  </Grid>
}

function DataTable({data , headers , isgrade}){

      const table =isgrade?data.map((el)=>{
          return <tr>
            <td>{el.domain}</td>
              <td>{el.topic}</td>
              <td>{el.participant_score}</td>
              <td>{el.school_range}</td>
              <td>{(el.school_average).toFixed(2)}</td>
          </tr>
      }):data.map((el)=>{
          return <tr>
              <td>{el.question}</td>
              <td>{el.topic}</td>
              <td>{el.is_correct?'Yes':'No'}</td>
              <td>{el.correct_in_school}</td>
              <td>{el.correct_in_country}</td>
              <td>{el.diffculty}</td>
          </tr>
      })
      
  return <table className="datatable">
      
      <tr>
          {headers.map((el,index)=>{
              return<th>{el}</th>
          })}
        
      </tr>
      {table}
  </table> 
}
function App() {
  const stag = 'https://api.stag.simccms.org/api'
  const prod = 'https://api.simccms.org/api'
  const dev = 'https://api.dev.simccms.org/api'
  document.title='Individual Report'
  const index = window.location.href.slice(window.location.href.indexOf('?')+10 , window.location.href.indexOf('&'))
  const cert = window.location.href.slice(window.location.href.indexOf('&')+16 , window.location.href.length)
 
  const makeSureIsArray = arr => Array.isArray(arr) ? arr : Object.values(arr)
  const [report , setReport] = useState()
  const [loading , setLoading] = useState(false)
  const [msg , setMsg] = useState('')
  const controller = new AbortController()
  const signal = controller.signal
  const getMethod = (path, params, signal) => {
    const headers = {
      'Content-type': 'application/json',
      Accept: 'application/json',
   
   }
   let options = {
    headers: { ...headers}
 }
    let status
    if (signal !== undefined) options.signal = signal
    return new Promise((resolve, reject) => {
       fetch(`${prod}/${path}${params}`, options).then(res => {   
          status = res.status
          return res.json()
       }).then(data => {
          resolve(data)
          console.log(data)
       }).catch(error => {
             console.log(error)
       })
    })
 }
  useEffect(()=>{
    setLoading(true)
    getMethod(`participant/report/by-certificate?index_no=${String(index)}&certificate_no=${String(cert)}`,'', signal).then((res)=>{
      setReport(res.data)
      console.log(res.data)
      setLoading(false)
      setMsg(res.message)
    })
  
  },[])
  const generateReport = ()=>{
    window.open(`${prod}/participant/report/by-certificate?index_no=${String(index)}&certificate_no=${String(cert)}&as_pdf=${1}`, '_blank', 'noreferrer');
//    console.log( detectZoom.device())
//   if( detectZoom.device()<=1){
//     const input = document.getElementById('report')
//     html2canvas(input).then(canvas => {
//       let dataURL = canvas.toDataURL('image/png' , 'jpeg' , 0 ,0);

   
//         const pdf = new jsPDF({
//           orientation: "portrait",
//           unit: "in",
//           format: [(input.offsetHeight*0.010417)+1, (input.offsetWidth*0.010417)+1]
//         });
// console.log(input.clientHeight ,input.offsetWidth )
//         pdf.addImage(dataURL, 'PNG', .6, .6);
//         pdf.save(`${report.general_data.particiapnt}.pdf`);

      
//     });}else{
//       alert('make sure to set zoom page on 100% or lower before you extract the pdf')
//     }

  }
  console.log(typeof('report.general_data.school'))
return <Box className='wrapperBox'>
  {loading?<Loader/>:report?<Grid container style={{padding:'30px' }} id={'report'} className="printable">
      <Grid width={'100%'}>
      <NunitoText value={report.general_data.competition} fontSize={40} fontWeight={700} italic color='#144A94'></NunitoText>
      <NunitoText value='Individual student Report' fontSize={30} fontWeight={700} italic color='#144A94'></NunitoText>
      <hr></hr>
      </Grid>
     
      <Grid container style={{marginTop:'20px' , width:'100%' ,}}>
              <Grid width={'100%'} marginBottom={'15px'}>
                <Grid display={'flex'} alignItems={'center'}  marginBottom={'20px'} justifyContent={'flex-start'}>
              <NunitoText value={report.general_data.particiapnt} fontSize={30} fontWeight={700} italic color='#144A94' width={'30%'} align={'left'}></NunitoText>
              <Button style={{'fontSize':'15px' , 'border':'1px solid' , 'borderRadius':'18px'}} onClick={()=>generateReport()}>Pdf download</Button>
              </Grid>
              <Grid flexDirection={'row'} display={'flex'} justifyContent={'flex-start'} width={'100%'}>
                  <Grid style={{textAlign:'left' , width:'20%'}}>
                  <NunitoText value='Grade' fontSize={20} fontWeight={700}  color='#999'  align={'left'}></NunitoText>
                  <NunitoText value={report.general_data.grade} fontSize={20} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                  </Grid>
                  <Grid style={{textAlign:'left'}}>
                  <NunitoText value='School' fontSize={20} fontWeight={700}  color='#999' align={'left'}></NunitoText>
                  <NunitoText value={typeof(report.general_data.school)==='object'?report.general_data.school.name:report.general_data.school} fontSize={20} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                  </Grid>
              </Grid>
              </Grid>
              <Grid width={'100%'} marginBottom={'35px'}>
              <NunitoText value='Performance by Questions' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
              <NunitoText value='Table belowshows which questions Student got correct.' fontSize={20} fontWeight={700}  color='#999'  align={'left'}></NunitoText>
              <StudentAnswers questions={report.performance_by_questions}/>
              </Grid>
              <Grid width={'100%'} marginBottom={'35px'}>
              <NunitoText value='Performance by Topics' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
              <NunitoText value="Percentage on top:Student's average by topics | Percentage middle:School average by topics | Percentage below:National average by topics" fontSize={20} fontWeight={700}  color='#999'  align={'left'}></NunitoText>
              <StudentAnswersTopics topics={report.performance_by_topics}/>
              <div style={{'marginLeft':'1%' , 'marginTop':'2%' , 'fontSize':'25px'}}>
              <div style={{'display':'flex' , 'alignItems':'center' , 'marginBottom':'1%'}}> <IoMdPerson style={{'marginRight':'1%'}}/>Percentage of the questions the current student score correct for this topic.</div>
              <div style={{'marginBottom':'1%'}}> <FaGraduationCap  style={{'marginRight':'1%'}}/>Percentage of the questions the school's students get it correct for this topic.</div>
              <div style={{'marginBottom':'1%'}}> <AiFillFlag  style={{'marginRight':'1%'}}/>Percentage of the questions the country's students get it correct for this topic</div>
              </div>
              </Grid>
              <Grid width={'100%'} marginBottom={'35px'}>
              <NunitoText value='Grade Performance Analysis' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
              <DataTable data={report.grade_performance_analysis} headers={['Domain','Topic' , 'Your Score','SchoolRange','Average']} isgrade={true}/>
              <div style={{'marginLeft':'1%' , 'marginTop':'2%' , 'fontSize':'25px'}}>
              <div style={{'marginBottom':'1%'}}> <span style={{'fontWeight':"bold"}}>Average:</span> Median score for this topic within the school</div>
              <div><span style={{'fontWeight':"bold"}}>SchoolRange:</span> The lowest and highest score the participant's scored for this topic within the school</div>
              </div>
              </Grid>
              <Grid width={'100%'}>
              <NunitoText value='Grade Performance Analysis' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
              <DataTable data={report.analysis_by_questions} headers={['Question','Topic','Answer Correct','% Correct in Your School' ,'% Correct in Your Country' ,'Level of Difficulty']} isgrade={false}/>
              <div style={{'marginLeft':'1%' , 'marginTop':'2%' , 'fontSize':'25px'}}>
              <div style={{'marginBottom':'1%'}}> <span style={{'fontWeight':"bold"}}>Answer Correct: </span>Whether the student answered the question correctly or not.</div>
              <div style={{'marginBottom':'1%'}}><span style={{'fontWeight':"bold" ,}}>% Correct in Your School: </span> Pecentage of students in the school who answered the question correctly.</div>
              <div><span style={{'fontWeight':"bold"}}>% Correct in Your Country: </span> Percentage of students in the country who answered the question
correctly.</div>
              </div>
              </Grid>
      </Grid>
     
  </Grid>:<h1 style={{margin:'15px'}}>{msg}</h1>}
</Box>

}

export default App;
